<template>
  <div>
    <cui-forgot-password />
  </div>
</template>
<script>
import CuiForgotPassword from '@/components/cleanui/system/Auth/ForgotPassword'
export default {
  components: {
    CuiForgotPassword,
  },
}
</script>
